import React from 'react';
import PropTypes from 'prop-types';
import DownloadAppPage from '../components/DownloadAppPage';
import DownloadAppPageWithRobuxUpsell from '../components/DownloadAppPageWithRobuxUpsell';
import useExperiments from '../hooks/useExperiments';

const MOBILE_LANDING_PAGE_LAYER = 'Website.MobileLandingPage';
const LANDING_PAGE_TYPE_ROBUX_UPSELL = 'robux_upsell';
const LANDING_PAGE_TYPE_ROBUX_UPSELL_WITH_BACKGROUND = 'robux_upsell_with_background';

function DownloadAppBase({ translate }) {
  const ixpResult = useExperiments(MOBILE_LANDING_PAGE_LAYER);

  if (ixpResult) {
    const landingPageType = ixpResult.LandingPageType;
    switch (landingPageType) {
      case LANDING_PAGE_TYPE_ROBUX_UPSELL_WITH_BACKGROUND:
        return (
          <DownloadAppPageWithRobuxUpsell
            translate={translate}
            shouldShowBackgroundImage
            shouldUseDarkerButtons={false}
          />
        );
      case LANDING_PAGE_TYPE_ROBUX_UPSELL:
        return (
          <DownloadAppPageWithRobuxUpsell
            translate={translate}
            shouldShowBackgroundImage={false}
            shouldUseDarkerButtons
          />
        );
      default:
        return <DownloadAppPage translate={translate} />;
    }
  }
  return null;
}

DownloadAppBase.propTypes = {
  translate: PropTypes.func.isRequired
};

export default DownloadAppBase;
