import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@rbx/foundation-ui';
import downloadAppConstants from '../constants/downloadAppConstants';
import {
  HandlePrimaryLinkClicked,
  HandleSecondaryLinkClicked,
  HandleRobuxLinkClicked,
  HandleGiftCardLinkClicked,
  HandleRedeemCodeLinkClicked
} from '../services/downloadAppService';

const DownloadAppPageWithRobuxUpsell = ({
  translate,
  shouldShowBackgroundImage,
  shouldUseDarkerButtons
}) => (
  <div
    className={classNames('background-image dark-theme', {
      'show-background-image': shouldShowBackgroundImage
    })}>
    <div className='justify-between height-full flex flex-col'>
      {/* This forces the next div to keep a space from the top of the page */}
      <div />
      <div className='flex flex-col'>
        <div className='icon-logo' />
        <div className='items-stretch padding-x-xlarge margin-top-large gap-y-medium flex flex-col'>
          <Button
            as='a'
            variant='Emphasis'
            size='Large'
            className='fill'
            href={
              downloadAppConstants.isIosDevice
                ? downloadAppConstants.robloxiOSMobileLink
                : downloadAppConstants.robloxMobileLink
            }
            onClick={() => {
              HandlePrimaryLinkClicked(false);
            }}>
            {translate('Action.ContinueInAppLowerCase')}
          </Button>

          <Button
            variant='ActionUtility'
            size='Large'
            className='stroke-system-contrast stroke-standard'
            onClick={HandleSecondaryLinkClicked}>
            {translate('Action.ContinueToWebsite')}
          </Button>
        </div>
      </div>
      <div className='padding-xlarge gap-y-xxlarge flex flex-col'>
        <div>
          <div>
            <span className='content-emphasis text-heading-medium'>
              {translate('Message.EnjoyMoreRobux')}
            </span>
          </div>
          <div>
            <span className='content-default text-body-medium'>
              {translate('Description.MoreRobuxComputerWebGiftcards')}
            </span>
          </div>
        </div>
        <div className='gap-y-medium flex flex-col'>
          <Button
            as='a'
            variant={shouldUseDarkerButtons ? 'SoftEmphasis' : 'Standard'}
            size='Medium'
            className={classNames('fill', {
              'button-white': !shouldUseDarkerButtons
            })}
            onClick={HandleRobuxLinkClicked}
            href={downloadAppConstants.buyRobuxLink}>
            {translate('Label.BuyRobux')}
          </Button>
          <div className='justify-stretch gap-x-medium flex'>
            <Button
              as='a'
              variant={shouldUseDarkerButtons ? 'Standard' : 'ActionUtility'}
              size='Medium'
              className={classNames('basis-0 grow-1', {
                'stroke-system-contrast stroke-standard': !shouldUseDarkerButtons
              })}
              onClick={HandleGiftCardLinkClicked}
              href={downloadAppConstants.buyGiftCardLink}>
              {translate('Label.BuyGiftCard')}
            </Button>
            <Button
              as='a'
              variant={shouldUseDarkerButtons ? 'Standard' : 'ActionUtility'}
              size='Medium'
              className={classNames('basis-0 grow-1', {
                'stroke-system-contrast stroke-standard': !shouldUseDarkerButtons
              })}
              onClick={HandleRedeemCodeLinkClicked}
              href={downloadAppConstants.redeemCodeLink}>
              {translate('Action.RedeemACode')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

DownloadAppPageWithRobuxUpsell.propTypes = {
  translate: PropTypes.func.isRequired,
  shouldShowBackgroundImage: PropTypes.bool.isRequired,
  shouldUseDarkerButtons: PropTypes.bool.isRequired
};

export default DownloadAppPageWithRobuxUpsell;
