import { DeviceMeta, EnvironmentUrls } from 'Roblox';

const downloadAppConstants = {
  isAndroidDevice: DeviceMeta ? DeviceMeta().isAndroidDevice : false,
  isIosDevice: DeviceMeta ? DeviceMeta().isIosDevice : false,
  noDeviceString: 'Roblox',

  robloxMobileLink: 'robloxmobile://',
  robloxiOSMobileLink: '/share?type=home&code=0',
  homeLink: '/home',
  buyRobuxLink: '/upgrades/robux',
  buyGiftCardLink: '/giftcards?ref=mobile-web-splash-page',
  redeemCodeLink: '/redeem',

  apiUrls: {
    enrollAbtestingApi: {
      url: `${EnvironmentUrls.abtestingApiSite}/v1/enrollments`,
      retryable: false,
      withCredentials: false
    }
  },
  abtestingRequest: {
    subjectType: 'BrowserTracker',
    experimentName: 'Mobile.DownloadApp.ShowAppStoreRating'
  },
  abtestingResponse: {
    status: {
      enrolled: 'Enrolled'
    }
  }
};

export { downloadAppConstants as default };
