import { useEffect, useState } from 'react';
import { ExperimentationService } from 'Roblox';

const getExperimentsForLayer = async experimentLayer => {
  if (ExperimentationService?.getAllValuesForLayer) {
    const ixpResult = await ExperimentationService.getAllValuesForLayer(experimentLayer);
    await ExperimentationService.logLayerExposure(experimentLayer);
    return ixpResult;
  }
  return {};
};

const useExperiments = experimentLayer => {
  const [ixpResult, setIxpResult] = useState(null);
  useEffect(() => {
    getExperimentsForLayer(experimentLayer).then(
      function success(data) {
        setIxpResult(data);
      },
      function error() {
        // return empty object if call to experimentation service fails
        // this behaves as if user is not enrolled in any experiment
        setIxpResult({});
      }
    );
  }, [experimentLayer]);
  return ixpResult;
};

export default useExperiments;
