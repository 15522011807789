import React from 'react';
import PropTypes from 'prop-types';
import downloadAppConstants from '../constants/downloadAppConstants';

import {
  HandlePrimaryLinkClicked,
  HandleSecondaryLinkClicked,
  HandleAndroidStoreLink,
  HandleIosStoreLink
} from '../services/downloadAppService';

const DownloadAppPage = ({ translate }) => {
  const continueInAppLink =
    downloadAppConstants.isAndroidDevice || downloadAppConstants.isIosDevice;

  let iconTitle;
  if (downloadAppConstants.isAndroidDevice) {
    iconTitle = translate('Heading.RobloxForAndroid');
  } else if (downloadAppConstants.isIosDevice) {
    iconTitle = translate('Heading.RobloxForIos');
  } else {
    iconTitle = downloadAppConstants.noDeviceString;
  }

  return (
    <div className='background-image show-background-image'>
      <div className='icon-logo' />
      <p className='play-on-mobile'>{translate('Label.PlayGamesInMobile')}</p>
      <button
        className='icon-container'
        type='button'
        onClick={() => HandlePrimaryLinkClicked(true)}>
        <div className='icon-image'>
          <span className='icon-logo-r-silver-darkbg-large' />
          <p className='roblox-for-platform'>
            <b>{iconTitle}</b>
          </p>
        </div>
      </button>
      <div className='link-container'>
        {continueInAppLink ? (
          <a
            className='btn-primary-md primary-link'
            href={
              downloadAppConstants.isIosDevice
                ? downloadAppConstants.robloxiOSMobileLink
                : downloadAppConstants.robloxMobileLink
            }
            onClick={() => HandlePrimaryLinkClicked(false)}>
            {translate('Action.ContinueInApp')}
          </a>
        ) : (
          <div className='app-store-link-container'>
            <button
              type='button'
              aria-label={translate('Heading.RobloxForAndroid')}
              className='app-store-link-button android-link'
              onClick={HandleAndroidStoreLink}
            />
            <button
              type='button'
              aria-label={translate('Heading.RobloxForIos')}
              className='app-store-link-button ios-link'
              onClick={HandleIosStoreLink}
            />
          </div>
        )}
        <button className='browser-link' type='button' onClick={HandleSecondaryLinkClicked}>
          <u>{translate('Action.ContinueInBrowser')}</u>
        </button>
      </div>
    </div>
  );
};

DownloadAppPage.propTypes = {
  translate: PropTypes.func.isRequired
};

export default DownloadAppPage;
