export const rootElementId = 'download-the-app-container';

export const translation = {
  common: [],
  features: [
    'Feature.DownloadApp',
    'Feature.SponsoredPages',
    'Purchasing.RedeemGameCard',
    'Feature.Premium'
  ]
};
